<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container sticky with-subtitle">
            <h1 class="title">{{$t(`pages.points.title`)}}</h1>
            <p class="subtitle" v-html="$t(`pages.points.subtitle`, { points })"></p>
        </div>

        <div id="with-sticky-header" class="with-sticky-header row">
            <div v-if="loaded" class="cards">
                <div class="card" v-for="(card, index) in gift_cards" :key="index" @click="open_modal(card)">
                    <div class="image-container">
                        <img :src="card.image_url" alt="card">
                    </div>
                    
                    <div class="bottom">
                        <p class="title">{{ Object.keys(card.options)[0] }}$</p>
                        <span class="amount">{{ $t(`pages.points.amount`, { amount: format_points(card.options[25]) }) }}</span>
                    </div>
                </div>
            </div>

            <div v-else class="card loading"></div> 
        </div>
        
        <div v-if="modal.modal" class="modal purchase-gift-card" :class="{ active: modal.modal }">
            <div class="modal-card">
                <div class="modal-title">
                    <p class="title">{{$t('pages.points.modal.title')}}</p>
                    <font-awesome-icon @click="close_modal()" :icon="['fa', 'square-xmark']" />
                </div>

                <div class="modal-body" v-if="modal.info && !modal.success.success">
                    <div class="row">   
                        <div class="left">
                            <img :src="modal.info.image_url" alt="card">
                        </div>

                        <div class="right">
                            <div class="info">
                                <p class="points">{{ Object.keys(modal.info.options)[0] }}$</p>
                                <p class="amout">{{ format_points(modal.info.options[25]) }} points</p>
                            </div>  

                            <button :class="['cta cta-primary', { 'loading': modal.loading, disabled: !can_purchase(modal.info) }]" @click="purchase()">{{ $t('pages.points.modal.obtain') }}</button>
                            <p class="disclaimer" v-if="!can_purchase(modal.info)" v-html="$t('pages.points.modal.disclaimer', { number: friends_to_invite(modal.info) })"></p>
                        </div>
                    </div>
                </div>

                <div class="modal-body" v-if="modal.success.success">
                    <div class="row success">
                        <font-awesome-icon :icon="['fa', 'circle-check']" />
                       <p v-html="$t('pages.points.modal.success', { key: $t(`pages.points.cards.${modal.success.data.key}`), amount: modal.success.data.amount })"></p>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '../../../components/Layout.vue';
import { bus } from '../../../main';

export default {
    name: 'Messages',

    components: {
        Layout
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    watch: {
        'modal.modal': function() {
            const self = this;
            if(self.modal.modal) { 
                self.$utils._open_modal(); 
            }
            else { self.$utils._close_modal(); }
        },
    },

    data() {
        return {
            routeName: 'points',
            classes: { dashboard: true, points: true },

            loaded: false,

            gift_cards: [],
            points: 0,

            modal: {
                modal: false,
                loading: false,
                info: null,
                success: {
                    success: false,
                    data: null
                }
            }
        }
    },

    methods: {
        get_gift_cards_and_points: function() {
            const self = this;

            self.$axios
                .get(`gift-cards`)
                .then(response => {
                    if(response.status == 200) {
                        self.gift_cards = response.data.gift_cards;
                        self.points = response.data.points;
                        
                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                            self.loaded = true;
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loaded = true;
                });
        },

        format_points: function(amount) {
            if(amount === 12500) { return '12,500' }
            return amount;
        },

        open_modal: function(card) {
            const self = this;

            self.modal.modal = true;
            self.modal.info = card;
            self.modal.success = {
                success: false,
                data: null
            }
        },

        close_modal: function() {
            const self = this;  
            
            self.$utils._close_modal();
            self.modal.modal = false;
            self.modal.info = null;
            self.modal.success = {
                success: false,
                data: null
            }
        },

        can_purchase: function(info) {
            const self = this;
            return parseFloat(self.points) >= parseFloat(info.options[25])
        },

        friends_to_invite: function(info) {
            const self = this;

            const needed = parseFloat(info.options[25]) - parseFloat(self.points);
            const friends = needed / 2500;

            return Math.ceil(friends);
        },

        purchase: function() {
            const self = this;

            self.modal.loading = true;
            self.modal.success = {
                success: false,
                data: null
            }

            const data = {
                key: self.modal.info.key,
                amount: 25,
                points: 12500,
            }

            self.$axios
                .post('/gift-card', data)
                .then(response => {
                    self.modal.loading = false;

                    if(response.status == 200) {
                        self.modal.success  ={
                            success: true,
                            data
                        };

                        self.modal.info = null;

                        self.$nextTick(() => {
                            self.get_gift_cards_and_points();
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                    
                    self.modal.loading = false;
                });
        }
    },

    mounted() {
        const self = this; 
        self.get_gift_cards_and_points();
    }
}
</script>