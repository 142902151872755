<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <div class="welcome-container">
            <div class="title-container">
                <h1 class="heading">{{$t('getstarted.welcome.title')}}</h1>
            </div>

            <div class="card">
                <div class="progress-bar" :style="`width: ${progress_bar_width}%`"></div>

                <div class="card-body">
                    <p class="text" v-html="$t('getstarted.welcome.card.disclaimer')"></p>

                    <div class="survey">
                        <p class="title">{{$t('getstarted.welcome.card.survey.title')}}</p>
                        <p class="desc">{{$t('getstarted.welcome.card.survey.desc')}}</p>
                        
                        <div v-if="!hide_survey" class="cta-container">
                            <button :class="['cta cta-primary cta-slim']" @click="track_volunteer_interested()">{{$t('language.yes')}}</button>
                            <button :class="['cta cta-outline dark cta-slim']" @click="hide_survey = true">{{$t('language.no')}}</button>
                        </div>

                        <div v-else>
                            <p class="form-success"><font-awesome-icon :icon="['fa', 'circle-check']" />{{$t('getstarted.welcome.card.survey.thanks')}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lb-bg"></div>
        </div> 
    </layout>
</template>

<script>
import { mapActions } from 'vuex';

import Layout from "../../../components/Layout.vue";

export default {
    name: "get-started",

    components: {
        Layout,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    watch: {
        progress_bar_width: function () {
            const self = this;
            if(self.progress_bar_width === 108) {
                self.redirect();
            }
        }
    },

    data() {
        return {
            progress_bar_width: 0,
            hide_survey: false,
            wait_time: 15,
            routeName: "welcome",
            classes: {
                'get-started': true, 'welcome': true
            }
        }
    },

    methods: {
        ...mapActions([
            'change_current_step',
            'setToken'
        ]),

        setup_redirect: function() {
            const self = this;
            setInterval(function () { self.progress_bar_width = self.progress_bar_width + 1 }, 1000 / (108 / self.wait_time));
        },

        redirect: function() {
            this.$utils._navigate_to_name('dashboard');
        },

        track_volunteer_interested: function() {
            const self = this;
            self.hide_survey = true;

            self.$axios
                .post('/volunteer/interested')
                .then(response => {
                    if(response.status == 200) {
                        self.setToken(response.data);
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;
        self.setup_redirect();
    }
}
</script>